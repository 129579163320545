const newsData = [
  {
    date: "11-09-2023",
    image: "https://koripallopaikat.com/koripallopaikat/159/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/159/1.webp",
    alt: "Hiomotie, 5",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Hiomotie,%205",
    linkText: "Hiomotie, 5",
  },
  {
    date: "16-08-2023",
    image: "https://koripallopaikat.com/koripallopaikat/158/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/158/1.webp",
    alt: "Espoonlahdentie, 2-4",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Espoonlahdentie,%202-4",
    linkText: "Espoonlahdentie, 2-4",
  },
  {
    date: "28-07-2023",
    image: "https://koripallopaikat.com/koripallopaikat/157/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/157/1.webp",
    alt: "Westendintie, 37",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Westendintie,%2037",
    linkText: "Westendintie, 37",
  },
  {
    date: "24-07-2023",
    image: "https://koripallopaikat.com/koripallopaikat/news/museum.webp",
    largeImage:
      "https://koripallopaikat.com/koripallopaikat/news/large/museum.webp",
    alt: "After almost a year break, Koripallopaikat.com is back.",
    text: " After almost a year break, we are back.",
    longText:
      " After almost a year break, Koripallopaikat.com is back. There are some improvements coming soon, as well as new courts.",
    link: "https://koripallopaikat.com/news",
    linkText: "Breaking news!",
  },
  {
    date: "12-10-2022",
    image: "https://koripallopaikat.com/koripallopaikat/156/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/156/1.webp",
    alt: "Haukilahdenkatu, 6",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Haukilahdenkatu,%206",
    linkText: "Haukilahdenkatu, 6",
  },
  {
    date: "08-10-2022",
    image: "https://koripallopaikat.com/koripallopaikat/155/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/155/1.webp",
    alt: "Haukilahdenkatu, 4",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Haukilahdenkatu,%204",
    linkText: "Haukilahdenkatu, 4",
  },
  {
    date: "03-10-2022",
    image: "https://koripallopaikat.com/koripallopaikat/154/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/154/1.webp",
    alt: "Runoratsunkatu, 2",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Runoratsunkatu,%202",
    linkText: "Runoratsunkatu, 2",
  },
  {
    date: "23-09-2022",
    image: "https://koripallopaikat.com/koripallopaikat/153/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/153/1.webp",
    alt: "Nuottapolku, 11",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Nuottapolku,%2011",
    linkText: "Nuottapolku, 11",
  },
  {
    date: "19-09-2022",
    image: "https://koripallopaikat.com/koripallopaikat/152/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/152/1.webp",
    alt: "Matinsyrjä, 5",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Matinsyrjä,%205",
    linkText: "Matinsyrjä, 5",
  },
  {
    date: "16-09-2022",
    image: "https://koripallopaikat.com/koripallopaikat/151/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/151/1.webp",
    alt: "Telamäenkuja, 5",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Telamäenkuja,%205",
    linkText: "Telamäenkuja, 5",
  },
  {
    date: "14-09-2022",
    image: "https://koripallopaikat.com/koripallopaikat/150/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/150/1.webp",
    alt: "Telamäenkuja, 3",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Telamäenkuja,%203",
    linkText: "Telamäenkuja, 3",
  },
  {
    date: "12-09-2022",
    image: "https://koripallopaikat.com/koripallopaikat/149/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/149/1.webp",
    alt: "Linnaistenkatu, 9",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Linnaistenkatu,%209",
    linkText: "Linnaistenkatu, 9",
  },
  {
    date: "27-08-2022",
    image: "https://koripallopaikat.com/koripallopaikat/148/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/148/1.webp",
    alt: "Päivärinne",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Päivärinne",
    linkText: "Päivärinne",
  },
  {
    date: "24-08-2022",
    image: "https://koripallopaikat.com/koripallopaikat/147/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/147/1.webp",
    alt: "Asemakatu, 1",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Asemakatu,%201",
    linkText: "Asemakatu, 1",
  },
  {
    date: "19-08-2022",
    image: "https://koripallopaikat.com/koripallopaikat/146/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/146/1.webp",
    alt: "Opintie, 5",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Opintie,%205",
    linkText: "Opintie, 5",
  },
  {
    date: "30-07-2022",
    image: "https://koripallopaikat.com/koripallopaikat/55/2.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/55/2.webp",
    alt: "Bernhardinkatu, 7",
    text: " was updated.",
    longText: " was updated. New pictures are added.",
    link: "https://koripallopaikat.com/courts/Bernhardinkatu,%207",
    linkText: "Bernhardinkatu, 7",
  },
  {
    date: "24-07-2022",
    image: "https://koripallopaikat.com/koripallopaikat/145/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/145/1.webp",
    alt: "Talvelantie, 1",
    text: " court was added.",
    longText:
      " court was added. Next week I will add a couple of courts from Fiskars and Lohja.",
    link: "https://koripallopaikat.com/courts/Talvelantie,%201",
    linkText: "Talvelantie, 1",
  },
  {
    date: "17-07-2022",
    image: "https://koripallopaikat.com/koripallopaikat/144/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/144/1.webp",
    alt: "Naulakalliontie, 1",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Naulakalliontie,%201",
    linkText: "Naulakalliontie, 1",
  },
  {
    date: "14-07-2022",
    image: "https://koripallopaikat.com/koripallopaikat/143/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/143/1.webp",
    alt: "Kivikonkaari, 31",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Kivikonkaari,%2031",
    linkText: "Kivikonkaari, 31",
  },
  {
    date: "07-07-2022",
    image: "https://koripallopaikat.com/koripallopaikat/142/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/142/1.webp",
    alt: "Nallitie, 3",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Nallitie,%203",
    linkText: "Nallitie, 3",
  },
  {
    date: "04-07-2022",
    image: "https://koripallopaikat.com/koripallopaikat/141/2.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/141/2.webp",
    alt: "Osmontie, 47",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Osmontie,%2047",
    linkText: "Osmontie, 47",
  },
  {
    date: "25-06-2022",
    image: "https://koripallopaikat.com/koripallopaikat/140/2.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/140/2.webp",
    alt: "Rahakamarinkatu, 2",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Rahakamarinkatu,%202",
    linkText: "Rahakamarinkatu, 2 (Keskuspuisto)",
  },
  {
    date: "19-06-2022",
    image: "https://koripallopaikat.com/koripallopaikat/139/2.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/139/2.webp",
    alt: "Töölönkatu, 41",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Töölönkatu,%2041",
    linkText: "Töölönkatu, 41",
  },
  {
    date: "27-05-2022",
    image: "https://koripallopaikat.com/koripallopaikat/138/2.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/138/2.webp",
    alt: "Kirkonkyläntie, 99",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Kirkonkyläntie,%2099",
    linkText: "Kirkonkyläntie, 99",
  },
  {
    date: "25-05-2022",
    image: "https://koripallopaikat.com/koripallopaikat/137/2.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/137/2.webp",
    alt: "Rutarsinpolku, 2",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Rutarsinpolku,%202",
    linkText: "Rutarsinpolku, 2",
  },
  {
    date: "21-05-2022",
    image: "https://koripallopaikat.com/koripallopaikat/136/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/136/1.webp",
    alt: "Eteläinen Hesperiankatu, 38",
    text: " court was added.",
    longText:
      " court was added. Next week I will try to add a few courts from Vantaa and one more from Taka-Töölö.",
    link: "https://koripallopaikat.com/courts/Eteläinen%20Hesperiankatu,%2038",
    linkText: "Eteläinen Hesperiankatu, 38",
  },
  {
    date: "14-05-2022",
    image: "https://koripallopaikat.com/koripallopaikat/135/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/135/1.webp",
    alt: "Pohjoinen Hesperiankatu, 22",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Pohjoinen%20Hesperiankatu,%2022",
    linkText: "Pohjoinen Hesperiankatu, 22",
  },
  {
    date: "04-05-2022",
    image: "https://koripallopaikat.com/koripallopaikat/5/2.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/5/2.webp",
    alt: "Salpausseläntie, 3",
    text: " was updated.",
    longText: " was updated. New pictures are added.",
    link: "https://koripallopaikat.com/courts/Salpausseläntie,%203",
    linkText: "Salpausseläntie, 3",
  },
  {
    date: "01-05-2022",
    image: "https://koripallopaikat.com/koripallopaikat/134/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/134/1.webp",
    alt: "Rakovalkeantie, 11",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Rakovalkeantie,%2011",
    linkText: "Rakovalkeantie, 11",
  },
  {
    date: "30-04-2022",
    image: "https://koripallopaikat.com/koripallopaikat/133/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/133/1.webp",
    alt: "Ylipalontie, 1",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Ylipalontie,%201",
    linkText: "Ylipalontie, 1",
  },
  {
    date: "25-04-2022",
    image: "https://koripallopaikat.com/koripallopaikat/132/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/132/1.webp",
    alt: "Vuorikatu, 13",
    text: " court was added.",
    longText:
      " court was added, that is all from Lahti for now. Next, there will be a few courts from Paloheinä & Töölö (Helsinki).",
    link: "https://koripallopaikat.com/courts/Vuorikatu,%2013",
    linkText: "Vuorikatu, 13",
  },
  {
    date: "23-04-2022",
    image: "https://koripallopaikat.com/koripallopaikat/131/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/131/1.webp",
    alt: "Vuorikatu, 29",
    text: " court was added.",
    longText:
      " court was added. I will add another court from Lahti in the next couple of days.",
    link: "https://koripallopaikat.com/courts/Vuorikatu,%2029",
    linkText: "Vuorikatu, 29",
  },
  {
    date: "06-04-2022",
    image: "https://koripallopaikat.com/koripallopaikat/130/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/130/1.webp",
    alt: "Piispankatu, 3-7",
    text: " court was added.",
    longText: " court was added.",
    link: "https://koripallopaikat.com/courts/Piispankatu,%203-7",
    linkText: "Piispankatu, 3-7",
  },
  {
    date: "02-04-2022",
    image: "https://koripallopaikat.com/koripallopaikat/129/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/129/1.webp",
    alt: "Edelfeltin Bulevardi, 2",
    text: " court was added.",
    longText:
      " court was added. I will add more courts from Porvoo in the next couple of days.",
    link: "https://koripallopaikat.com/courts/Edelfeltin%20Bulevardi,%202",
    linkText: "Edelfeltin Bulevardi, 2",
  },
  {
    date: "23-03-2022",
    image: "https://koripallopaikat.com/koripallopaikat/34/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/34/1.webp",
    alt: "Messitytönkatu, 4",
    text: " was updated as well as Ahmatie, 1.",
    longText: " was updated as well as Ahmatie, 1. New pictures are added.",
    link: "https://koripallopaikat.com/courts/Messitytönkatu,%204",
    linkText: "Messitytönkatu, 4",
  },
  {
    date: "21-03-2022",
    image: "https://koripallopaikat.com/koripallopaikat/72/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/72/1.webp",
    alt: "Hämeentie, 80",
    text: " was updated as well as Laivastokuja, 2.",
    longText:
      " was updated as well as Laivastokuja, 2. New pictures are added.",
    link: "https://koripallopaikat.com/courts/Hämeentie,%2080",
    linkText: "Hämeentie, 80",
  },
  {
    date: "19-03-2022",
    image: "https://koripallopaikat.com/koripallopaikat/27/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/89/1.webp",
    alt: "Porttitie, 5",
    text: " was updated as well as Rusthollarintie, 7 & Rusthollarintie, 6.",
    longText:
      " was updated as well as Rusthollarintie, 7 & Rusthollarintie, 6. New pictures are added.",
    link: "https://koripallopaikat.com/courts/Porttitie,%205",
    linkText: "Porttitie, 5",
  },
  {
    date: "24-02-2022",
    image: "https://koripallopaikat.com/koripallopaikat/84/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/84/2.webp",
    alt: "Hammarskjöldintie, 5",
    text: " was updated as well as Puotilan metrokatu, 2.",
    longText:
      " was updated as well as Puotilan metrokatu, 2. New pictures are added.",
    link: "https://koripallopaikat.com/courts/Hammarskj%C3%B6ldintie,%205",
    linkText: "Hammarskjöldintie, 5",
  },
  {
    date: "10-02-2022",
    image: "https://koripallopaikat.com/koripallopaikat/18/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/18/2.webp",
    alt: "Lucina Hagmanin polku",
    text: " was updated as well as Leikkikuja, 4.",
    longText: " was updated as well as Leikkikuja, 4. New pictures are added.",
    link: "https://koripallopaikat.com/courts/Lucina%20Hagmanin%20polku",
    linkText: "Lucina Hagmanin polku",
  },
  {
    date: "30-01-2022",
    image: "https://koripallopaikat.com/koripallopaikat/16/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/16/2.webp",
    alt: "Sturenkatu, 6",
    text: " is updated as well as Mäkelänkatu, 72 & Kullervonkatu, 11.",
    longText:
      " is updated as well as Mäkelänkatu, 72 & Kullervonkatu, 11. New pictures are added.",
    link: "https://koripallopaikat.com/courts/Sturenkatu,%206",
    linkText: "Sturenkatu, 6",
  },
  {
    date: "28-01-2022",
    image: "https://koripallopaikat.com/koripallopaikat/33/2.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/33/1.webp",
    alt: "Hyväntoivonkatu, 5",
    text: " is updated. New pictures are added.",
    longText: " is updated. New pictures are added.",
    link: "https://koripallopaikat.com/courts/Hyv%C3%A4ntoivonkatu,%205",
    linkText: "Hyväntoivonkatu, 5",
  },
  {
    date: "24-01-2022",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/128.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/128.webp",
    alt: "Rionkatu, 10",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Rionkatu,%2010",
    linkText: "Rionkatu, 10",
  },
  {
    date: "22-01-2022",
    image: "https://koripallopaikat.com/koripallopaikat/13/2.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/12/2.webp",
    alt: "Neljäs linja, 11",
    text: " is updated as well as Lastenkodinkatu, 2-10.",
    longText:
      " is updated as well as Lastenkodinkatu, 2-10 (Baana). New pictures are added.",
    link: "https://koripallopaikat.com/courts/Neljäs%20linja,%2011",
    linkText: "Neljäs linja, 11",
  },
  {
    date: "10-01-2022",
    image: "https://koripallopaikat.com/koripallopaikat/9/1.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/9/2.webp",
    alt: "Berliininkatu, 4",
    text: " is updated as well as Arabianpolku, 2 & Arabianpolku, 3.",
    longText:
      " is updated as well as Arabianpolku, 2 & Arabianpolku, 3. New pictures are added.",
    link: "https://koripallopaikat.com/courts/Berliininkatu,%204",
    linkText: "Berliininkatu, 4",
  },
  {
    date: "03-01-2022",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/127.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/127.webp",
    alt: "Veräjäpellonkatu, 14",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Veräjäpellonkatu,%2014",
    linkText: "Veräjäpellonkatu, 14",
  },
  {
    date: "01-01-2022",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/126.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/126.webp",
    alt: "Loviselundinpolku",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Loviselundinpolku",
    linkText: "Loviselundinpolku",
  },
  {
    date: "28-12-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/125.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/125.webp",
    alt: "Veräjäpellonkatu, 12",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Veräjäpellonkatu,%2012",
    linkText: "Veräjäpellonkatu, 12",
  },
  {
    date: "24-12-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/124.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/124.webp",
    alt: "Veräjävahdinpolku",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Veräjävahdinpolku",
    linkText: "Veräjävahdinpolku",
  },
  {
    date: "21-12-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/123.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/123.webp",
    alt: "Veräjäpellonkatu, 2",
    text: " court was added.",
    longText:
      " court was added. There are going to be a few more courts from Leppävaara soon.",
    link: "https://koripallopaikat.com/courts/Veräjäpellonkatu,%202",
    linkText: "Veräjäpellonkatu, 2",
  },
  {
    date: "16-12-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/122.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/122.webp",
    alt: "Pikkukoskentie, 23",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Pikkukoskentie,%2023",
    linkText: "Pikkukoskentie, 23",
  },
  {
    date: "15-12-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/121.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/121.webp",
    alt: "Teinintie, 12",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Teinintie,%2012",
    linkText: "Teinintie, 12",
  },
  {
    date: "13-12-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/20.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/20.webp",
    alt: "Moreenitie, 4",
    text: " is updated. New pictures are added.",
    longText:
      " is updated. New pictures are added. Ongoing construction works nearby. The 'court' is still available, but deeply not recommended.",
    link: "https://koripallopaikat.com/courts/Moreenitie,%204",
    linkText: "Moreenitie, 4",
  },
  {
    date: "10-12-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/19.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/19.webp",
    alt: "Moreenitie, 2",
    text: " is updated. New pictures are added.",
    longText: " is updated. New pictures are added.",
    link: "https://koripallopaikat.com/courts/Moreenitie,%202",
    linkText: "Moreenitie, 2",
  },
  {
    date: "07-12-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/120.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/120.webp",
    alt: "Siltavoudintie, 24",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Siltavoudintie,%2024",
    linkText: "Siltavoudintie, 24",
  },
  {
    date: "02-12-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/119.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/119.webp",
    alt: "Mikkolankuja, 3",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Mikkolankuja,%203",
    linkText: "Mikkolankuja, 3",
  },
  {
    date: "29-11-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/118.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/118.webp",
    alt: "Pihlajistontie, 1",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Pihlajistontie,%201",
    linkText: "Pihlajistontie, 1",
  },
  {
    date: "26-11-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/46.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/46.webp",
    alt: "Jengipolku, 8",
    text: " is updated. New pictures are added.",
    longText: " is updated. New pictures are added.",
    link: "https://koripallopaikat.com/courts/Jengipolku,%208",
    linkText: "Jengipolku, 8",
  },
  {
    date: "19-11-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/117.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/117.webp",
    alt: "Kuntokatu, 17",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Kuntokatu,%2017",
    linkText: "Kuntokatu, 17",
  },
  {
    date: "16-11-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/116.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/116.webp",
    alt: "Rajakentäntie, 1",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Rajakentäntie,%201",
    linkText: "Rajakentäntie, 1",
  },
  {
    date: "14-11-2021",
    image: "https://koripallopaikat.com/koripallopaikat/news/news.webp",
    largeImage:
      "https://koripallopaikat.com/koripallopaikat/news/large/news.webp",
    alt: "Kbar",
    text: " command+k interface was added to the site. Try it out – press cmd/ctrl and k.",
    link: "https://github.com/timc1/kbar",
    linkText: "Kbar",
  },
  {
    date: "12-11-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/115.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/115.webp",
    alt: "Iiluodonpiha, 1",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Iiluodonpiha,%201",
    linkText: "Iiluodonpiha, 1",
  },
  {
    date: "10-11-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/6.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/6.webp",
    alt: "Ylästöntie, 3",
    text: " is updated. Basketball field has been completely redone.",
    longText:
      " is updated. Basketball field has been completely redone, now it has 2 baskets and an artificial grass surface.",
    link: "https://koripallopaikat.com/courts/Ylästöntie,%203",
    linkText: "Ylästöntie, 3",
  },
  {
    date: "09-11-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/114.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/114.webp",
    alt: "Leikosaarentie, 16",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Leikosaarentie,%2016",
    linkText: "Leikosaarentie, 16",
  },
  {
    date: "08-11-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/113.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/113.webp",
    alt: "Kallvikinniementie, 1",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Kallvikinniementie,%201",
    linkText: "Kallvikinniementie, 1",
  },
  {
    date: "06-11-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/112.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/112.webp",
    alt: "Kallahdenraitti, 3",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Kallahdenraitti,%203",
    linkText: "Kallahdenraitti, 3",
  },
  {
    date: "05-11-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/111.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/111.webp",
    alt: "Prammikuja, 3",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Prammikuja,%203",
    linkText: "Prammikuja, 3",
  },
  {
    date: "04-11-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/110.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/110.webp",
    alt: "Kauppakartanonkatu, 28",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Kauppakartanonkatu,%2028",
    linkText: "Kauppakartanonkatu, 28",
  },
  {
    date: "02-11-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/109.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/109.webp",
    alt: "Prinsessantie, 2",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Prinsessantie,%202",
    linkText: "Prinsessantie, 2",
  },
  {
    date: "01-11-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/108.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/108.webp",
    alt: "Lutherinkatu, 18",
    text: " court was added. Next week I plan to add about 7 more courts.",
    link: "https://koripallopaikat.com/courts/Lutherinkatu,%2018",
    linkText: "Lutherinkatu, 18",
  },
  {
    date: "30-10-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/107.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/107.webp",
    alt: "Merikannontie, 6",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Merikannontie,%206",
    linkText: "Merikannontie, 6",
  },
  {
    date: "27-10-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/106.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/106.webp",
    alt: "Aurinkotuulenkatu, 11",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Aurinkotuulenkatu,%2011",
    linkText: "Aurinkotuulenkatu, 11",
  },
  {
    date: "27-10-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/105.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/105.webp",
    alt: "Urheilukalastajankuja, 1",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Urheilukalastajankuja,%201",
    linkText: "Urheilukalastajankuja, 1",
  },
  {
    date: "25-10-2021",
    image: "https://koripallopaikat.com/koripallopaikat/news/news.webp",
    largeImage:
      "https://koripallopaikat.com/koripallopaikat/news/large/news.webp",
    alt: "News page",
    text: " is created and added to the main page.",
    link: "https://koripallopaikat.com/news",
    linkText: "News page",
  },
  {
    date: "20-10-2021",
    image: "https://koripallopaikat.com/koripallopaikat/news/insta.webp",
    largeImage:
      "https://koripallopaikat.com/koripallopaikat/news/large/insta.webp",
    alt: "Instagram",
    text: " is out and running. Feel free to follow!",
    link: "https://www.instagram.com/koripallopaikat/",
    linkText: "Our Instagram account",
  },
  {
    date: "08-10-2021",
    image: "https://koripallopaikat.com/koripallopaikat/searchPics/104.webp",
    largeImage: "https://koripallopaikat.com/koripallopaikat/listPics/104.webp",
    alt: "Tuhkimontie, 10",
    text: " court was added.",
    link: "https://koripallopaikat.com/courts/Tuhkimontie,%2010",
    linkText: "Tuhkimontie, 10",
  },
];

export default newsData;
